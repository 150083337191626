import { createContext } from 'react'

import { CartProps } from './cart.types'

export const initialState: CartProps = {
  cart: [],
  isCartOpen: false,
  addToCart(): void {},
  removeFromCart(): void {},
  setIsCartOpen(): void {},
  updateQuantity(): void {},
  clearCart(): void {},
}

export const CartContext = createContext<CartProps>(initialState)
